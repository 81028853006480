<template>
<div>
  <div v-if="!permissions.app" class="m-4 p-4 text-centre"><h1>No access permissions</h1></div>
  <div v-if="permissions.app" class="app-background" style="min-height: 800px">
  <router-view></router-view>
</div>
</div>
</template>

<script>
import ac from '@/libs/accesscontrol'

export default {
  components: {
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.$stat.log({ page: 'Importing app start', action: 'open Importing app' })
    this.permissions.app = ac.can(this.user.acgroups).readAny('appimporting').granted
  },
  data () {
    return {
      baseUrl: this.$config.BASE_URL,
      permissions: {}
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
